.result-item-slide {
  @apply min-h-[400px] p-3 md:max-w-[320px] lg:min-h-[480px] lg:max-w-[380px];

  &.has-stats {
    @apply md:max-w-[640px] lg:max-w-[760px];

    .result-item-inner {
      @apply md:grid md:grid-cols-2;
    }
  }
}

.result-item-inner {
  @apply shadow md:h-full;
}

.result-item-cell {
  @apply p-3 md:min-h-[400px] md:w-1/2 xl:w-1/4;

  &.has-stats {
    @apply md:w-full xl:w-1/2;

    .result-item-inner {
      @apply md:grid md:grid-cols-2;
    }
  }
}

.result-item-grid {
  @apply flex flex-col flex-wrap md:flex-row md:justify-center;
}

.result-item-stats:not(.is-only-stats):before {
  @apply absolute top-24 left-0 z-2 hidden md:block;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 28px 0 28px 28px;
  border-color: transparent transparent transparent #ffffff;
}

.element-ourresults.has-controls {
  .our-results-slider,
  .splide {
    @apply md:ml-14;
  }

  .swiper {
    @apply md:pr-16;
  }
}
